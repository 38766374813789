import React, { useState } from 'react'
import styled from 'styled-components'
import { faChevronLeft, faParagraph } from '@fortawesome/pro-solid-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import phone from '../assets/images/phone.svg'
import colors from '../components/color-css/color'
import data from '../assets/data/paymentErrorFormat.json'
import ValidationIndicator from '../components/ValidationIndicator'
import Layout from '../components/layout/index'
import Membership from '../components/Membership'
import { Container } from '../components/Membership'
import { navigate } from 'gatsby-link'
import { ReactReduxContext } from 'react-redux'
import SEO from '../components/seo'
import { useSelector } from 'react-redux'
import { RootState } from 'store/'
import {
  STATE_FROM_SUBURB
} from '../components/FormHighOrder/types/YourWorkField'

export type Props = {
  sectionHeader?: string
  sectionTitle?: string
  backButton?: string
  validVisibility?: boolean
  errors?: { message: string }[]
}

interface PaymentErrorProps {
  validVisibility?: boolean
}

const PaymentErrorWrapper = styled.div`
    font-family: museo-sans, sans-serif;
    @media screen and (max-width: 1024px) {
      margin: auto;
    }
    @media screen and (max-width: 870px) {
      width: 100%;
    }
`

const PaymentErrorHeader = styled.div<PaymentErrorProps>`
    color: ${props => props.validVisibility ? colors.red : colors.blue_17_percent};
    text-align: left;
    font-family: museo-sans, sans-serif;
    font-weight:900;
    font-size: 1.625rem;
    letter-spacing: -0.08px;
    opacity: 1;
    margin-bottom: 1.9375rem;
    `

const Paragraph = styled.p<PaymentErrorProps>`
    display: ${props => props.validVisibility ? 'none' : 'block'};\
    font-family: museo-sans, sans-serif;
    font-size: 1rem;
    font-style: italic;
    letter-spacing: -0.05px;
    color: ${colors.blue_17_percent};
    max-width: 39.75rem;
`

const ReturnIcon = styled(FontAwesomeIcon)`
    color: ${colors.tooltip_icon};
    height: 0.813rem;
    text-align: center;
`

const ReturnStep = styled.a`
    color: ${colors.blue_17_percent};
    text-decoration: underline;
    margin-left: 0.625rem;
    opacity: 0.7;
    cursor: pointer;

`

const ReturnStepWrapper = styled.div`
    margin-top: 1.875rem;
    &:hover ${ReturnStep}{
        opacity: 1;
    }
`

const ContactWrapper = styled.div`
    background-color: ${colors.grey_light};
    margin-top: 1.875rem;
    max-width: 39.75rem;
    height: 7.688rem;
    border: 0 solix;
    border-radius: 0.625rem;
    @media screen and (max-width: 870px) {
      width: 100%;
      max-width: 100%;
    }
`

const ContactContentWrapper = styled.div`
    padding: 2.125rem 12.8125rem 2.125rem 1.875rem;
    display: flex;
    flex-direction: column;
    width: 400px;
    @media screen and (max-width: 870px) {
      padding: 2.125rem 0.625rem 2.125rem 0.625rem;
      width: 100%;
    }
    @media screen and (max-width: 576px) {
      width: 90%;
    }
`

const ContactHeader = styled.div`
    font-weight: 900;
    color: ${colors.blue_17_percent};
    font-size: 0.938rem;
    margin: 0;
    width: max-content;
    @media screen and (max-width: 870px) {
      text-align: center;
      width: 100%;
    }
`

const Wrapper = styled.div`
    display: flex;
    margin-top: 0.938rem;
    @media screen and (max-width: 870px) {
      width: 100%;
    }
`
const PhoneIcon = styled(FontAwesomeIcon)`
    color: ${colors.blue_17_percent};
    text-align: center;
`

const EmailIcon = styled(FontAwesomeIcon)`
    color: ${colors.blue_17_percent};
    text-align: center;
`

const ContactInfo = styled.a`
    text-decoration: none;
    color: ${colors.blue_17_percent};
    margin: 0 0 0 0.625rem;
    cursor: pointer;
    pointer-events: none;
    @media screen and (max-width: 991px) {
      cursor: pointer;
      pointer-events: auto;
    }
    @media screen and (max-width: 576px) {
      font-size: 0.75rem;
    }
    @media screen and (max-width: 400px) {
      margin-left: 0.3rem;
    }
`

const ContactEmail = styled.a`
    text-decoration: none;
    color: ${colors.blue_17_percent};
    margin-left: 0.625rem;
    cursor: pointer;
    @media screen and (max-width: 576px) {
      font-size: 0.75rem;
    }
    @media screen and (max-width: 400px) {
      margin-left: 0.3rem;
    }
`

const ContactContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.125rem;
    &:hover ${EmailIcon}{
        color: ${colors.blue_darker};
    }
    &:hover ${ContactEmail}{
        color: ${colors.blue_darker};
    }
    @media screen and (max-width: 870px) {
      font-size: 0.875rem;
      margin: auto;
    }
    @media screen and (max-width: 576px) {
      font-size: 0.75rem;
    }
`

const PageWrapper = styled.div`
    margin: 7.5rem 5.75rem 0 5.75rem;
    display: flex;
    justify-content: space-between;
    max-width: 78.5rem;
    @media screen and (max-width: 1024px) {
      margin-bottom: 3.125rem;
      margin-top: 3.9375rem;
    }
    @media screen and (min-width: 1441px) {
        margin: 7.5rem auto 0 auto;
    }
    @media screen and (max-width: 576px) {
      margin: 3.9375rem 0.9375rem 3.125rem 0.9375rem;
    }
  }
`

const SpacingBetweenFormAndMemberShip = styled.div`
  flex: 1 0 0.625rem;
  @media screen and (min-width: 1024px) {
    min-width: 5.5rem;
  };
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const RestyleMembershipContainer = styled(Container)`
    margin-top: -2.6625rem;
`

export const PaymentErrorPageFormat: React.FC<Props> = (props: any) => {
  const customError = typeof props?.errors !== 'undefined'

  const getBranch: string | undefined = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  let branch = getBranch || props?.location?.state?.branch

  if (branch === 'NT' || branch === 'Broken Hill' || typeof branch === 'undefined') {
    branch = 'SA'
  }

  const branchSpecific: any = {
    SA: {
      branchText: 'Or contact the SDA Union SA, NT & Broken Hill',
      email: "ask@sda.com.au",
      phone: "8139 1000"
    },
    QLD: {
      branchText: 'Or contact the SDA Union Queensland',
      email: "secretary@sdaq.asn.au",
      phone: "07 3833 9500"
    },
    WA: {
      branchText: 'Or contact the SDA Union Western Australia',
      email: "asksda@sdawa.asn.au",
      phone: "1300 275 732"
    },
    TAS: {
      branchText: 'Or contact the SDA Union Tasmania',
      email: "secretary@sdatas.asn.au",
      phone: "1300 152 851"
    },
    Newcastle: {
      branchText: 'Or contact the SDA Union Newcastle & Northern',
      email: "secretary@sdan.org.au",
      phone: "1300 732 4357"
    },
  }

  const formatPhone = (number?: string): string => {
    if (number?.length === 10) {
      return `${number.substring(0, 4)} ${number.substring(4, 7)} ${number.substring(7, 10)}`
    }
    if (number?.length === 11) {
      return `${number.substring(0, 4)} ${number.substring(4, 7)} ${number.substring(7, 11)}`
    }
    if (number?.length === 8) {
      return `${number.substring(0, 4)} ${number.substring(4, 8)}`
    }

    return `${number}`
  }

  return (
    <PageWrapper>
      <SEO
        noindex={true}
        lang={'en'}
        title={'SDA Union | Error'}
      />
      <PaymentErrorWrapper>
        <PaymentErrorHeader validVisibility={props.validVisibility}>
          {customError ? 'Submission Error' : props.sectionHeader}
        </PaymentErrorHeader>
        {customError && Array.isArray(props?.errors) ? props?.errors?.map((error: any) => {
          return (
            <>
              <ValidationIndicator
                visibility={props.validVisibility}
                isCorrect={false}
                message={error?.message ? error?.message : error}
              />
            </>
          )
        }) : null}
        {customError && typeof props?.errors === 'string' ? (
          <>
            <ValidationIndicator
              visibility={props.validVisibility}
              isCorrect={false}
              message={props.errors}
            />
          </>
        ) : null}
        {!customError ? (
          <>
            <ValidationIndicator
              visibility={props.validVisibility}
              isCorrect={false}
              message={props.sectionTitle}
            />
          </>
        ) : null}
        <ReturnStepWrapper>
          <ReturnIcon icon={faChevronLeft} />
          <ReturnStep href="/" onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}>
            {props.backButton}
          </ReturnStep>
        </ReturnStepWrapper>
        <ContactWrapper>
          <ContactContentWrapper>
            <ContactHeader>{branchSpecific?.[branch]?.branchText}</ContactHeader>
            <Wrapper>
              <ContactContent style={{ marginRight: "2.1875rem" }}>
                <EmailIcon icon={faEnvelope} />
                <ContactEmail
                  href={`mailto:${branchSpecific?.[branch]?.email}`}
                  target="_blank">
                  {branchSpecific?.[branch]?.email}
                </ContactEmail>
              </ContactContent>
              <ContactContent>
                <PhoneIcon icon={faPhone} />
                <ContactInfo
                  href={`tel:${branchSpecific?.[branch]?.phone}`}
                >
                  {formatPhone(branchSpecific?.[branch]?.phone)}
                </ContactInfo>
              </ContactContent>
            </Wrapper>
          </ContactContentWrapper>
        </ContactWrapper>
      </PaymentErrorWrapper>
      <SpacingBetweenFormAndMemberShip />
      <RestyleMembershipContainer>
        <Membership containerWidth='100%' />
      </RestyleMembershipContainer>
    </PageWrapper>
  )
}

const PaymentErrorPage = (props: any) => {

  return (
    <Layout>
      <PaymentErrorPageFormat
        errors={props?.location?.state?.errors}
        validVisibility={true}
        sectionHeader={data.sectionHeading[0]}
        sectionTitle={data.sectionTitle[0]}
        backButton={data.backButton[0]}
      />
    </Layout>
  )
}

export default PaymentErrorPage
